<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateAdmin' }"> 新增管理員 </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
          :actionViewRouteLink="'AdminDetail'"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'Admin',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tableHeaders: [
      { text: '管理員帳號', value: 'username' },
      { text: '管理員種類', value: 'admin_type' },
      {
        text: '',
        value: 'actionView',
        sortable: false,
        align: 'end',
      },
    ],
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 20,
    tablePageLimitOptions: [20, 50, 100],
    formItemTotal: 0,
    formData: [],
  }),
  methods: {
    async getAdminData() {
      this.tableLoading = true

      try {
        const payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_admin', payload)
        this.$func.log('-----Get Admin List List-----')
        this.$func.log(result)

        this.formItemTotal = result.total

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          temp.push({
            id: resultData.id,
            username: resultData.username,
            admin_type: resultData.admin_type,
          })
        }

        this.formData = temp
      } catch (error) {
        this.$func.log('-----Get Admin List List fail-----')
        this.$func.log(error)

        let msg = ''

        if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.formData = []
        this.formItemTotal = 0
      } finally {
        this.tableLoading = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getAdminData()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getAdminData()
    }
  },
}
</script>
